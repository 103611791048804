<template>
  <div class="container mt--8">
    <div class="grid-row">
      <div class="col-8 col-start-3">
        <h1 class="headline headline--main t--center">Impressum</h1>
        <h4 class="headline mt--6">
          Die BurdaForward GmbH ist ein Unternehmen der Hubert Burda Media.
        </h4>
        <section class="mt--5">
          <h4 class="headline">Verantwortlich</h4>
          <p class="mt--1">
            BurdaForward GmbH<br />
            St.-Martin-Straße 66<br />
            81541 München<br />
            Tel.: +49 89 9250 4500<br />
            E-Mail: content-team@burda-forward.de<br />
            Geschäftsführung: Oliver Eckert (Vorsitzender), Philipp Brunner,
            Thomas Koelzer, Martin Lütgenau, Daniel Steil, Dr. Tanja zu
            Waldeck<br />
            Amtsgericht München HRB 213375<br />
            Ust.-ID-Nr.: DE 296 466 883
          </p>
        </section>
        <section class="mt--5">
          <h4 class="headline">Urheberrechte</h4>
          <p class="mt--1">
            © Copyright BurdaForward GmbH, München (Deutschland). Alle Rechte
            vorbehalten. Text, Bilder, Grafiken, Sound, Animationen und Videos
            sowie deren Anordnung auf der Website der BurdaForward GmbH
            unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze.
            Der Inhalt dieser Website darf nicht zu kommerziellen Zwecken
            kopiert, verbreitet, verändert oder Dritten zugänglich gemacht
            werden. Wir weisen darauf hin, dass auf den Websites enthaltene
            Bilder teilweise dem Urheberrecht Dritter unterliegen. Wir verwenden
            unter anderem Bildmaterial von www.gettyimages.com und
            www.flaticon.com.
          </p>
        </section>
        <section class="mt--5">
          <h4 class="headline">Rechtlicher Hinweis</h4>
          <p class="mt--1">
            Jede auszugsweise oder gewerbliche Nutzung bedarf der vorherigen
            schriftlichen Genehmigung der BurdaForward GmbH.
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint",
};
</script>

<style lang="stylus"></style>
